//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api/v3'
import { uiStore } from '@/store'

export default {
  name: 'ExportModal',
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
    tasksExportURL () {
      return api.tasks.getExportURL()
    },
  },
  created () {
  },
  beforeDestroy () {
    // delete window['@@EntitySelector']
  },
  methods: {
    close () {
      uiStore.actions.hideModal()
    },
  },
}
